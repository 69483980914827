import { defineStore } from 'pinia';
import { ServiceArea } from '~/composables/types/ServiceArea';
import { ZipCode } from '~/composables/types/ZipCode';

export const useLanguageStore = defineStore('language', () => {
  const availableLanguages = ref<{ value: string; label: string; id: string }[]>([]);
  const setAvailableLanguages = (languages: { value: string; label: string; id: string }[]) => {
    availableLanguages.value = languages;
  };

  const languageOptions = ref<{ value: string; label: string; id: string }[]>([]);
  const setLanguageOptions = (languages: { value: string; label: string; id: string }[]) => {
    languageOptions.value = languages;
  };

  return {
    availableLanguages,
    setAvailableLanguages,
    languageOptions,
    setLanguageOptions,
  };
});
